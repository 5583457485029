@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700&display=swap');

$darkgrey: #0d0d0d;
$grey: #19181c;
$white: #cacaca;
$font: 'Mulish', sans-serif;

body {
    padding: 0;
    margin: 0 auto;
    max-width: 1400px;
    box-sizing: border-box;
    background-color: $grey;
    color: $white;
    font-family: $font;
    background-image: url('assets/background.jpg');
    background-size: cover;
    background-attachment: fixed;
}

header {
    //background-color: $darkgrey;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 2rem 2rem 3rem 2rem;

    .logo {
        width: 200px;
    }

    .svg-shape {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .svg-shape svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 60px;
    }

    .svg-shape .shape-fill {
        fill: $grey;
    }
}

.movie-form {
    padding: 2rem 2rem 1rem 2rem;
    display: flex;
    justify-content: center;

    form {
        display: flex;
    }

    input {
        padding: 0.25rem;
        font-size: 1rem;
        border: none;
        font-family: $font;
    }

    button {
        font-size: 1rem;
        padding: 0.4rem 0.5rem;
        margin-left: 1px;
        background-color: white;
        border: none;
        color: grey;
        font-family: $font;
        font-size: 0.9rem;
        font-weight: 500;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            background-color: white;
            color: $darkgrey;
        }
    }
}

.movies-search {
    padding: 2rem;
}

.movies-wrapper {
    padding: 2rem;
}

.movie-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    a {
        text-decoration: none;
        overflow: hidden;
        width: 100%;
        max-width: 250px;
    }
}

.movie-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;
    top: 0;
    transition: 0.3s;
    h2 {
        text-align: center;
        font-size: 1.2rem;
        flex-grow: 1;
    }

    img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        overflow: hidden;
    }
    .movie-desc {
        background-color: white;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex: 1;
        .note {
            padding-top: 0.5rem;
            .stars {
                margin-right: 0.5rem;
                svg {
                    fill: black;
                    padding: 0 0.05rem;
                    opacity: 0.6;
                    font-size: 0.8rem;
                }
            }
            .count {
                font-size: 0.7rem;
                color: $darkgrey;
                opacity: 0.5;
            }
        }
        .title {
            padding: 0.5rem 0.5rem 0.8rem 0.5rem;
            color: $darkgrey;
            text-decoration: none;
            flex-grow: 1;
            display: grid;
            align-content: center;
            //text-transform: uppercase;
            font-weight: bold;
            font-size: 1.1rem;
        }
        .date-sortie {
            color: $white;
            align-self: flex-end;
            background-color: black;
            padding: 0.2rem 0.5rem;
            border-radius: 10px 0 0 0;
            font-size: 0.8rem;
        }
    }
    &:hover {
        top: -20px;
    }
}

.movie-single {
    .backdrop {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        z-index: -2;
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($darkgrey, 0.8);
        z-index: -1;
    }
    .back {
        background-color: white;
        display: inline-flex;
        align-items: center;
        margin-bottom: 1rem;
        padding: 0.3rem 0.7rem;
        text-decoration: none;
        color: $darkgrey;
        transition: 0.3s;

        &:hover {
            background-color: rgba(white, 0.5);
        }

        svg {
            margin-right: 0.5rem;
            font-size: 1.2rem;
        }
    }
    h2 {
        margin-left: 2rem;
        text-transform: uppercase;
        font-size: 1.8rem;
    }
    .movie-rate {
        .note {
            display: flex;
            svg {
                fill: gold;
                padding: 0 0.05rem;
                opacity: 0.7;
                font-size: 1rem;
            }
            .count {
                font-weight: normal;
                font-size: 0.8rem;
                opacity: 0.6;
                margin-left: 0.3rem;
            }
        }
    }
    .content {
        padding: 2rem;
        overflow: hidden;
        z-index: 1;
    }

    .top-content {
        display: grid;
        grid-template-columns: min-content auto;
        align-items: center;
        column-gap: 8vw;
        row-gap: 2rem;
        margin-bottom: 2rem;
        background-color: rgba($darkgrey, 0.6);

        .details {
            .allocine img {
                width: 40px;
                height: auto;
                margin-top: 0.5rem;
                position: relative;
                transition: 0.3s;
                transform: scale(1);
                &:hover {
                    transform: scale(1.2);
                }
            }
            h2 {
                margin: 1rem 0 0.7rem 0;
                color: white;
                font-size: 2rem;
            }
            max-width: 700px;
            padding: 1rem;

            & > div {
                margin-bottom: 0.5rem;
            }

            span {
                font-weight: bold;
                color: white;
                &.genres-list,
                &.realisator {
                    font-weight: normal;
                    color: $white;
                }
            }
        }
    }

    .movie-casting {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 2rem;

        .actor {
            background-color: white;
            color: $darkgrey;
            padding: 1rem;

            &-name {
                font-weight: bold;
            }
            &-perso {
                font-size: 0.9rem;
                opacity: 0.8;
            }
        }

        img {
            max-width: 100%;
        }
    }

    .video {
        margin: 5rem auto;

        & > div {
            max-width: 100%;
        }
    }
}

@media (max-width: 979px) {
}

@media (max-width: 768px) {
    .movie-single {
        .top-content {
            grid-template-columns: 1fr;

            .poster {
                justify-self: center;
            }
        }
    }
}

@media (max-width: 500px) {
    header {
        padding: 2rem;
    }
    .movie-form {
        padding: 0;
    }
    .movies-wrapper {
        padding: 1rem;
        h2 {
            margin-top: 0;
        }
    }
    .movies-search {
        padding: 1rem;
    }
    .movie-list {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        margin-top: 3rem;

        .movie-card {
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .movie-single {
        .content {
            padding: 1rem;
        }
        .movie-casting {
            grid-gap: 1rem;
        }
    }
}
